import { ErrorProps } from 'next/error'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'

import { Modal } from '@/components/base'

export default function ErrorPage({ statusCode }: ErrorProps) {
  const { t } = useTranslation()
  return (
    <Modal className={'min-h-screen max-w-full rounded-none bg-background '}>
      <Modal.Content className={'h-screen w-screen p-5'}>
        <div className={'flex h-8 justify-start'}>
          <Image src={'/images/myassets-logo.svg'} width={200} height={33} alt={'myasset-logo'} quality={100} />
        </div>
        <div className={'grid grid-cols-1 pb-20'}>
          <div className={'flex items-center justify-center'}>
            <Image src={'/images/webError.png'} width={443} height={428} alt={'webError'} quality={100} />
          </div>

          <div className={'text-white lg:flex lg:flex-col lg:items-center lg:justify-center lg:px-10'}>
            <div className={'mb-5 text-center text-[32px]/9 font-bold'}>{t('NotFound')}</div>
            <div className={'text-center text-lg'}>{t('ErrorHandleContent')}</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
